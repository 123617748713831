import { inject, Injectable } from '@angular/core';
import {
  Auth,
  authState,
  sendPasswordResetEmail,
  user
} from '@angular/fire/auth';
import {
  Database,
  get,
  ref
} from '@angular/fire/database';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { PathConstants } from '../db-paths';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private afAuth = inject(Auth);
  private authState$ = authState(this.afAuth);
  private db = inject(Database);
  /**
   * This constructor is called when the service is initialized. It sets up a
   * subscription to the auth state of the user. When the user is logged in,
   * it fetches the user's details from Firestore. The user's details are
   * stored in a BehaviorSubject, which is an observable that allows other
   * components to subscribe to it and receive the user's details.
   *
   * @remarks
   * The user's details are stored in a Firestore document with the path
   * /users/$uid. The document contains the user's details, such as name,
   * email, and organization code.
   * The user's details are fetched from Firestore when the user is logged
   * in.
   * The user's details are stored in a BehaviorSubject, which is an
   * observable that allows other components to subscribe to it and receive
   * the user's details.
   */
  constructor() {
    this.authState$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }

        // Reference to the user details in the Realtime Database
        const userRef = ref(this.db, PathConstants.getUserDetailsPath(user.uid));

        return get(userRef).then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            // Check if the user has admin role
            return userData.roles?.admin === true ? userData : null;
          }
          return null;
        }).catch((error) => {
          console.error('Error fetching user data from Realtime Database:', error);
          return this.logout().then(() => of(null));
        });
      })
    ).subscribe((user) => {
      this.userSubject.next(user);
    });
  }


  getLoggedInUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  isValidLogin(): Observable<boolean> {
    return user(this.afAuth).pipe(map((user) => !!user));
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      this.userSubject.next(null);
    });
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.afAuth, email);
  }
}